<template>
  <Chart type="pie" :width="width" :height="height" :data="data" />
</template>

<script lang="ts">
import context from '@cloudfun/core'
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    }
  },
  setup () {
    const store = context.current?.model
    const darkMode = computed(() => store?.state.main.darkMode)
    const data = computed(() => {
      return {
        labels: ['Html', 'Vuejs', 'Laravel'],
        datasets: [
          {
            data: [15, 10, 65],
            backgroundColor: ['#FF8B26', '#FFC533', '#285FD3'],
            hoverBackgroundColor: ['#FF8B26', '#FFC533', '#285FD3'],
            borderWidth: 5,
            borderColor: darkMode.value ? '#303953' : '#fff'
          }
        ]
      }
    })

    return {
      data
    }
  }
})
</script>
